
import styled from '@emotion/styled'

export const TablaContainerStyled = styled.div`
    height: 325px;
    width: 50%; 
    min-width: 600px; 
    background: var(--secondary);
    padding:3px;
    border-radius: 5px;
    margin-top:5px;
    @media only screen and (max-width: 765px) {
        width: 100%;
        min-width: 200px;
    }
`
