import React, {useState, useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import TablaContainer from '../TablaContainer';
import getCoches from '../../services/getCoches';

const columns = [
  { field: 'id', 
    headerName: 'ID', 
    width: 160 },
  {
    field: 'modelo',
    headerName: 'Modelo',
    width: 200,
    editable: true,
  },
  {
    field: 'matricula',
    headerName: 'Matrícula',
    width: 200,
    editable: true,
  }
];

export default function TablaCochesPorMarca({opcion}) {

    const [data, setData] = useState([]);//Datos a mostrar

    useEffect(() => {    
        getCoches(opcion).then(response => {
            setData(response)
            }
        )
    },[opcion] );  
    
  return (
    <TablaContainer>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={4}
        rowsPerPageOptions={[4]}
        disableSelectionOnClick
      />
    </TablaContainer>
  );
}
