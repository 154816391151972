import React, {useState, useEffect, useContext} from 'react';
import { Field, Formik } from 'formik';

import postCoche from '../../services/postCoche';
import Contexto from '../../context/Context';
import ButtonForm from '../ButtonForm';
import Label from '../Label';
import Form from '../Form';
import ErrorMsg from '../ErrorMsg';
import ExitoMsg from '../ExitoMsg';
import Input from '../Input';

function Formulario(){
	
    const contexto = useContext(Contexto);
    const [marcas, setMarcas] = useState([]); //Datos select
    const [postError, setPostError] = useState(0); 

    const handleReset = () => {
        setPostError(0)
    };

    useEffect(() => {   
        setMarcas(contexto)   
    },[contexto]);

	return (
		<>
            <Formik
                initialValues={{modelo:"", matricula: "", marca: "0"}}
                onReset={handleReset}
                validate={({modelo, matricula, marca})=> {
                let errores = {}
                    //Valida modelo
                    if(!modelo){
                        errores.modelo = "Por favor, ingresa un modelo"
                    }
                    //Valida matricula
                    if(!matricula){
                        errores.matricula = "Por favor, ingresa un matricula"
                    }else if(!/^[0-9]{1,4}(?!.*(LL|CH))[BCDFGHJKLMNPRSTVWXYZ]{3}/.test(matricula)){
                        errores.matricula = "Introduzca un matricula válido"    
                    }                
                    //Valida marca
                    if(marca <=0 || marca > 3){
                        errores.marca = "Seleccione una marca"
                    }

                    return errores
                }}

                onSubmit={(values)=> {
                    const {modelo, matricula, marca} = values;
                    postCoche({modelo, matricula, marca}).then(response => {                           
                        const {id} = response;                       
                        id===null ? setPostError(1) : setPostError(2);
                    }) 
                }}                          
            >{
                ({values, errors, touched, handleSubmit, handleChange, handleBlur, handleReset})=> (
                    <Form  onSubmit={handleSubmit}>
                        <div>
                            <Label htmlFor="marca">Marca</Label>
                            <Field 
                                    as="select" 
                                    name="marca"
                                    id="marca"
                                    value={values.marca}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                            >
                                <option  value="0" >Escoja una opcion</option>
                                {marcas.map(({ id, nombre_marca }, index) => <option key={index} value={id} >{nombre_marca}</option>)}
                            </Field>
                            {//errores formulario
                                touched.marca && errors.marca &&  <ErrorMsg>{errors.marca}</ErrorMsg>  
                            }

                            <Label htmlFor="modelo">Modelo</Label>
                            <Input
                                type="text"
                                name="modelo"
                                placeholder="modelo"
                                id="modelo"
                                value={values.modelo}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />  
                            {//errores formulario
                            touched.modelo && errors.modelo &&  <ErrorMsg>{errors.modelo}</ErrorMsg>  
                            }

                        
                            <Label htmlFor="matricula">Matrícula</Label>
                            <Input
                                type="text"
                                name="matricula"
                                placeholder="matricula"
                                id="matricula"
                                value={values.matricula}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {//errores formulario
                            touched.matricula && errors.matricula &&  <ErrorMsg>{errors.matricula}</ErrorMsg>  
                            }
                            
                            <ButtonForm type="submit">Enviar</ButtonForm>
                        </div>
                        
                        {//error introduccion datos en bbdd
                            postError === 1 && <>           
                                <ErrorMsg>
                                    <p>No se ha podido ingresar el vehiculo</p>
                                    <p>El servicio no esta disponible o ese coche ya existe en el sistema</p>
                                </ErrorMsg>
                            </>     
                        }

                        {//exito introduccion datos en bbdd
                            postError === 2 && <>
                                <ExitoMsg>
                                    <p>Vehiculo ingresado</p>
                                    <ButtonForm type="reset" onClick={handleReset}>Insertar otro vehiculo</ButtonForm>
                                </ExitoMsg>
                            </>     
                        }
                    </Form>
                )}
            </Formik>               
		</>
	);
}

export default Formulario;