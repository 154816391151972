
import styled from '@emotion/styled'

export const TablaDocContainerStyled = styled.div`
    height: 460px;
    width: 95%; 
    background: var(--secondary);
    padding:3px;
    border-radius: 5px;
    margin-top:15px;
`
