import React from 'react';
import { ContainerStyled } from './styles'
import Nav from '../Nav';
import Section from '../Section';


export default function Container(){
    
    return (
        <ContainerStyled>
            <Nav/>
            <Section/>
        </ContainerStyled>
    )
}