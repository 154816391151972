
import styled from '@emotion/styled'

export const HeaderStyled = styled.header`
    background-color: var(--secondary);
    min-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px var(--primary);
    @media only screen and (max-width: 765px) {
        justify-content: center;
    }
`
