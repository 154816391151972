import React from 'react';
import { FooterStyled } from './styles'

export default function Footer(){
    
    return (
        <FooterStyled>
            <h4>Mikel Oceja 2021</h4>
        </FooterStyled>
    )
}