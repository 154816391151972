import React from 'react';
import { Route } from 'wouter';

import { SectionStyled } from './styles'
import Home from '../../pages/Home';
import Consultas from '../../pages/Consultas';
import NuevoVehiculo from '../../pages/NuevoVehiculo/index.js';

export default function Section(){
    
    return (
        <SectionStyled>
            <Route path="/" component={Home}/>
            <Route path="/consulta/" component={Consultas}/>
            <Route path="/nuevo-vehiculo/" component={NuevoVehiculo}/> 
        </SectionStyled>
    )
}