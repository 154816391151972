
import styled from '@emotion/styled'

export const ButtonFormStyled = styled.button`
        display: block;
        background: var(--accent);
        font-weight: 600;
        border: none;
        cursor: pointer;
        width: 100%;
        max-width: 200px;
        padding: 7px 9px;
        margin: 12px 0 0 0;
        border-radius: 5px;
        color: var(--light-text);
        font-size: 14px;
        transition: .3s ease all;
        &:hover {
            background: var(--accent-dark);
        }
    `
