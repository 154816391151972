import React from 'react';
import { useLocation } from 'wouter';
import ButtonNav from '../ButtonNav';
import { HeaderStyled } from './styles'

export default function Header(){

    //evaluo el path para establecer el src del logo correcto
    //si no al refrescar en un path != / el src de la imagen no concuerda
    const [parentLocation] = useLocation();    
    let logoSrc = ""
    
    if (parentLocation!== "/"){       
        logoSrc = logoSrc + "../";
    }

    logoSrc = logoSrc +"logo.png"
    return (<>
            <HeaderStyled>
                <ButtonNav href={`../`}>
                    <img src={logoSrc} alt="Logo" />
                </ButtonNav>            
            </HeaderStyled>
        </>
    )
}