
import styled from '@emotion/styled'

export const ContainerStyled = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    @media only screen and (max-width: 765px) {
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
    }
`