

export default function getDocumentosAlmacenados(){
    
    const apiUrl = `http://localhost:8080/pdf`
    
    return fetch(apiUrl)
        .then(res=>res.json())
        .then(response => {
            
            return response
            }
        )
}