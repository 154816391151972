import React from 'react'
import { InputStyled } from './styles'

function Input({children, type, name, placeholder, id, value, onChange, onBlur}) {

    return <InputStyled type={type}
                name={name}
                placeholder={placeholder}
                id={id}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            >
                {children}
            </InputStyled>
}

export default Input
