import React, {useState, useEffect, useRef} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ButtonForm from '../ButtonForm';

import deletePdf from '../../services/deletePdf';

export default function Dialogs({seleccionado,handler}) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const nodeRef = useRef();

  useEffect(() => {    
    setSelected(seleccionado)
  },[seleccionado] ); 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleAccept = () => {   
    deletePdf(selected).then(response => {
      handler()
      setOpen(false)
      return response
      }
    )
  };

  return (
    <div id="dialog-container">
      <ButtonForm onClick={handleClickOpen}>Borrar documento</ButtonForm>
      
      <Dialog
        ref={nodeRef}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle   id="alert-dialog-title">
          {`Desea borrar el documento `}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {`id: ${selected}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAccept} autoFocus>
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
