import styled from '@emotion/styled'

export const FooterStyled = styled.nav`
    background-color: var(--primary);
    min-height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--accent);
    border-top: solid 1px var(--secondary-dark);
`