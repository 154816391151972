import styled from '@emotion/styled'

export const SectionStyled = styled.section`
    background-color: var(--secondary-light);
    display: flex;
    width: 85%;
    min-height: 85vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
    @media only screen and (max-width: 765px) {
        width: 100%;
    }
`