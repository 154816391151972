import React, {useState, useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';

import getDocumentosAlmacenados from '../../services/getDocumentosAlmacenados';
import Dialogs from '../../components/Dialogs';
import TablaDocContainer from '../TablaDocContainer';
import ErrorMsg from '../ErrorMsg';

const columns = [
  {   field: 'id', 
      headerName: 'ID', 
      width: 300 },
  {
    field: 'nombre',
    headerName: 'Nombre',
    width: 250,
    editable: true,
  }
  ,
  {
    field: 'marcas',
    headerName: 'Marcas',
    width: 500,
    editable: true,
    autoWidth:true
  }
  ,
  {
    field: 'matriculas',
    headerName: 'Matriculas',
    width: 500,
    editable: true,
  }
  ,
  {
    field: 'modelos',
    headerName: 'Modelos',
    width: 500,
    editable: true,
  }
];

export default function TablaDocumentosAlmacenados() {

  const [data, setData] = useState([]);//Datos a mostrar
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {    
    getDocumentosAlmacenados().then(response => {
          setData(response)
          }
      )
  },[] );  

  const handler = () => {
    getDocumentosAlmacenados().then(response => {
      setData(response)
    })
  };
  return (
    data !==[]  ?   <TablaDocContainer>
      {//Comprobar con la maq apagada
    }
                      <DataGrid
                        checkboxSelection= {false}
                        rows={data}
                        columns={columns}
                        pageSize={6}
                        rowsPerPageOptions={[6]}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);                         
                        }}
                        selectionModel= {selectionModel}
                      />
        
                      <Dialogs seleccionado={selectionModel} handler = {handler}/>
                    </TablaDocContainer>
                    
                :   <ErrorMsg>No hay datos</ErrorMsg>
  );
}
