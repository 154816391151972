import styled from '@emotion/styled'

export const NavStyled = styled.nav`
    min-width: 175px;
    width: 18%;
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @media only screen and (max-width: 765px) {
        width: 100%;
        flex-direction: row;
    }
`
export const UlStyled = styled.ul`
    list-style: none;  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
`
export const LiStyled = styled.li`
    width: 100%;
    height: 100%;
    color: var(--secondary);
    font-weight: bold;
    cursor: pointer;
    padding: 15px 5px;
    border-bottom: solid 1px var(--secondary-dark);
    &:hover {
        background-color: var(--primary-light);
    }
`
export const LiSelectedStyled = styled.li`
    width: 100%;
    height: 100%;
    color: var(--primary);
    background-color: var(--secondary);
    font-weight: 700;
    cursor: pointer;
    padding: 15px 5px;
    border-bottom: solid 1px var(--secondary-dark);
    border-right: solid 6px var(--primary);
`