
export default function getMarcas(){

    const apiUrl = `http://localhost:8080/marca/select`
    return fetch(apiUrl)
        .then(res=>res.json())
        .then(response => {

            return response
            }
        )
}