
import Header from './components/Header';
import Container from './components/Container';
import Footer from './components/Footer';
import Contexto from './context/Context';
import { useEffect, useState } from 'react'; 
import getMarcas from './services/getMarcas';
import SinServicio from './pages/SinServicio';
import { AppStyled } from './styles'

function App() {

  const [marcas, setMarcas] = useState([{}]); //Datos para el contexto
  const [servicio, setServicio] = useState(false);

  useEffect(() => {       
    getMarcas().then(response => {
        setMarcas(response)    
        setServicio(true)
      }
    )
},[]);

  return (
    <>
      <Contexto.Provider value={marcas}>
        <AppStyled>
          <Header/>
            {
              servicio  ? <Container/>
                        : <SinServicio/>
            }        
          <Footer/>
        </AppStyled>
      </Contexto.Provider>
    </>
  );
}

export default App;
