
import styled from '@emotion/styled'

export const InputStyled = styled.input`   
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--primary-light);
    font-size: 14px;
    padding: 5px;
    color: var(--dark-text);
        &:hover {
            outline: none;
            border: 1px solid var(--primary);
        }
`
