
//import { APIKEY, API_URL } from './settings';

export default function postCoche ({modelo, matricula, marca}){

    const apiUrl = `http://localhost:8080/coche/`

    var valoresPost = {//creo el objeto a enviar
        "id":"null",
        "modelo":`${modelo}`,
        "matricula":`${matricula}`,
        "marca":{"id":marca}
    }
    
    return  fetch(apiUrl, {
                method: 'POST', 
                body: JSON.stringify(valoresPost), 
                headers:{
                'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                    .catch(error => {
                        console.log(error)
                    })
                        .then(response => {       
                            return response;
                            }
                        )
}