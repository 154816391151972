import styled from '@emotion/styled'
import CloudOffIcon from '@material-ui/icons/CloudOff';

export const MensajeSinServicioStyled = styled.h2`
    text-align:center;
    margin: 40px;
    color:var(--accent);
`
export const IconoSinServicioStyled = styled(CloudOffIcon)`
    align-self: center;
    scale: 10;
    margin: 80px;
    margin-bottom: 200px;
    color:var(--primary);
`
export const SectionStyled = styled.section`
    background-color: var(--secondary-light);
    display: flex;
    width: 100%;
    min-height: 85vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
    
`