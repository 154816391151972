
import { MensajeSinServicioStyled, IconoSinServicioStyled, SectionStyled } from './styles';

export default function SinServicio(){

    return (
        <>
            <SectionStyled>
                <MensajeSinServicioStyled>NO HAY SERVICIO</MensajeSinServicioStyled>
                <IconoSinServicioStyled/>
            </SectionStyled>
        </>
    );
}