import React, {useState, useEffect, useContext} from 'react';
import { Field, Formik } from 'formik';

import TablaCochesPorMarca  from '../../components/TablaCochesPorMarca';
import createPdf from '../../services/createPdf';
import Contexto from '../../context/Context';
import ButtonForm from '../ButtonForm';
import Label from '../Label';
import Form from '../Form';
import ErrorMsg from '../ErrorMsg';
import ExitoMsg from '../ExitoMsg';

function FormularioConsulta() {

    const contexto = useContext(Contexto);
    const [marcas, setMarcas] = useState([{}]);//Datos select
    const [opcion, setOpcion] = useState(0);//Marca seleccionada 
    const [pdfError, setPdfError] = useState(0);//Respuesta del PDF

    useEffect(() => {   
        setMarcas(contexto)
        
    },[contexto]);

    function handleSubmitPdf(e) {
        e.preventDefault(); 
        if (opcion !==0){
            createPdf(opcion).then(response => {
                if (response) {
                    setPdfError(1);
                }else {
                    setPdfError(2);
                }
            }) 
        }      
    }

	return (
		<>
            <Formik
                initialValues={{ marca: "0"}}
                validate={(valores)=> {  
                    let errores = {}
                    if(valores.marca <=0 || valores.marca > 3){
                        errores.marca = "Seleccione una marca"
                    }
                    return errores
                }}
                onSubmit={(valores)=> {
                    setPdfError(0)
                    setOpcion(valores.marca)
                    }
                }
            >
                {
                ({values, errors, touched, handleSubmit, handleChange, handleBlur})=> (
                    <>
                        <Form action="" onSubmit={handleSubmit}>
                            <div>
                                <Label htmlFor="marca">Marca</Label>
                                <Field 
                                        as="select" 
                                        name="marca"
                                        id="marca"
                                        value={values.marca}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        >
                                    <option  value="0" >Escoja una opcion</option>
                                    {marcas.map(({ id, nombre_marca }, index) => <option key={index} value={id} >{nombre_marca}</option>)}
                                </Field>
                                {//Errores validacion
                                    touched.marca && errors.marca &&  <ErrorMsg>{errors.marca}</ErrorMsg>  
                                }
                                <ButtonForm type="submit">Consultar</ButtonForm>
                            </div>                           
                        
                        </Form>                        
                    </>
                )}
            </Formik>

            <form onSubmit={handleSubmitPdf}>
                <ButtonForm type="submit">Enviar Pdf</ButtonForm>
            </form>

            {       //Respuesta Erronea PDF             
                pdfError === 1 && <>          
                    <ErrorMsg>
                        <p>Error al crear el Pdf</p>                             
                    </ErrorMsg>       
                </>          
            } 
            {       //Respuesta Correcta PDF             
                pdfError === 2 && <>          
                    <ExitoMsg>
                        <p>Pdf creado correctamente</p>                             
                    </ExitoMsg>       
                </>          
            } 

            {       //Muestra tabla segun la opcion           
                opcion !== 0 && <>            
                    <TablaCochesPorMarca opcion={opcion}/>            
                </>   
            }           
		</>
	);
}

export default FormularioConsulta;