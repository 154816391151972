

export default function createPdf(idMarca){
    
    const apiUrl =`http://localhost:8080/pdf/export/${idMarca}`
    
    return fetch(apiUrl)
        .then(res=>res.json())
        .then(response => {
                
            return response
            }
        )
}