import React from 'react';
import { NavStyled, LiStyled, UlStyled, LiSelectedStyled } from './styles'
import ButtonNav from '../ButtonNav';
import { useLocation } from 'wouter';

export default function Nav(){
    const [parentLocation] = useLocation();
    const textoHome = `Consultar documentos`;
    const textoNuevoVehiculo = `Nuevo vehiculo`;
    const textoConsulta = `Consultar vehiculos`;
    return (
        <NavStyled>
            <UlStyled>
                <ButtonNav href={`/`}>
                    {// Evalueo el path para establecer el link seleccionado
                        parentLocation===`/`   ?   <LiSelectedStyled>
                                                        {textoHome}    
                                                    </LiSelectedStyled>
                                                :   <LiStyled>
                                                        {textoHome}    
                                                    </LiStyled>                                                                        
                    }                                                        
                </ButtonNav>
                <ButtonNav href={`/nuevo-vehiculo/`}>
                    {
                        parentLocation ===`/nuevo-vehiculo/`    ?   <LiSelectedStyled>
                                                                        {textoNuevoVehiculo}    
                                                                    </LiSelectedStyled>
                                                                :   <LiStyled>
                                                                        {textoNuevoVehiculo}    
                                                                    </LiStyled>                                                                        
                    } 
                </ButtonNav>
                <ButtonNav href={`/consulta/`}>
                    {
                        parentLocation ===`/consulta/`      ?   <LiSelectedStyled>
                                                                    {textoConsulta}    
                                                                </LiSelectedStyled>
                                                            :   <LiStyled>
                                                                    {textoConsulta}    
                                                                </LiStyled>                                                                        
                    } 
                </ButtonNav>
            </UlStyled>
        </NavStyled>       
    )
}