
import styled from '@emotion/styled'

export const FormStyled = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 600px;
    margin-top: 5px;
    background: var(--secondary);
    border-radius: 5px;
    padding: 10px;
    box-shadow: var(--secondary-light) 0px 1px 4px;
    @media only screen and (max-width: 765px) {
        width: 100%;
        min-width: 200px;
    }
`
