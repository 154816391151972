

export default function getCoches(idMarca){
    
    const apiUrl = `http://localhost:8080/marca/${idMarca}`
    
    return fetch(apiUrl)
        .then(res=>res.json())
        .then(response => {
            const {coches} = response
            
            return coches
            }
        )
}